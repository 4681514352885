<template>
  <div>
    <!-- @todo add edit route -->
    <entity-header
      class="mb-10"
      :title="project.name"
      :is-archived="project.isArchived"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdateProjects"
      :loading="isLoading"
      @archive="archive"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder
      v-else
      :schema-list="[$options.detailsSchema, $options.teamSchema, $options.notificationsSchema]"
      :data="project"
      is-tabs
      :tabs="tabs"
      :active-tab="tab"
      @change-tab="changeTab"
    />
  </div>
</template>

<script>
// Services
import realtyService from '@/services/realty';
import analyticsService from '@/services/analytics';
import { normalizeProjectDetailed } from '@/services/serializations';

// Constants
import { PROJECTS_ARCHIVE, PROJECTS, PROJECTS_EDIT } from '@/constants/routes';
import { TAB_DETAILS, TAB_TEAM, TAB_NOTIFICATIONS } from '@/constants/tabs';
import { PROJECT, ARCHIVE_PROJECT, UNARCHIVE_PROJECT } from '@/constants/analyticsActions';
import { PROJECTS as PROJECTS_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

import ViewBuilder from '@/components/schema/ViewBuilder.vue';
import EntityHeader from '@/components/EntityHeader.vue';

import { detailsSchema, teamSchema, notificationsSchema } from '@/schemas/project.view.schema';

export default {
  name: 'ProjectsDetailed',

  components: { ViewBuilder, EntityHeader },

  data() {
    return {
      isLoading: false,
      project: {},
      id: this.$route.params.id,
      tab: 0,
      tabs: [
        {
          label: 'tab.details',
          value: TAB_DETAILS,
        },
        {
          label: 'tab.team',
          value: TAB_TEAM,
        },
        {
          label: 'tab.notifications',
          value: TAB_NOTIFICATIONS,
        },
      ],
    };
  },

  computed: {
    canUpdateProjects() {
      return this.$can(UPDATE, PROJECTS_SUBJECT);
    },

    backRoute() {
      if (this.$route.params.prevPageRoute) return this.$route.params.prevPageRoute;

      const routeName = this.project.isArchived ? PROJECTS_ARCHIVE : PROJECTS;

      return { name: routeName, query: { page: this.$route.query.prevPage || 1 } };
    },

    editRoute() {
      return { name: PROJECTS_EDIT, params: { id: this.id, schema: this.tabs[this.tab].value } };
    },
  },

  mounted() {
    analyticsService.track(PROJECT);
    this.fetchProject();
  },

  methods: {
    archive() {
      analyticsService.track(this.project.isArchived ? UNARCHIVE_PROJECT : ARCHIVE_PROJECT);
      realtyService.archiveProject(this.id, !this.project.isArchived).then(() => {
        this.project.isArchived = !this.project.isArchived;
      });
    },
    fetchProject() {
      this.isLoading = true;

      realtyService
        .getProjectById(this.$route.params.id)
        .then(project => {
          this.project = normalizeProjectDetailed(project);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    changeTab(newTab) {
      this.tab = typeof newTab === 'number' ? newTab : this.tab + 1;
    },
  },

  detailsSchema,
  teamSchema,
  notificationsSchema,
};
</script>
